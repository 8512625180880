<template>
  <v-container
    fluid
    grid-list-{xs
    through
    xl}
  >
    <v-layout
      v-if="logged"
      justify-center
      align-center
      style="position:fixed; top:0;bottom:0;left:0;right:0;z-index:100;"
    >
      <buy-card :img="'/assets/Chemistry-7th.svg'" :headline="'Premium Content'" @close="logged=false" />
    </v-layout>

    <v-layout align-center justify-center>
      <div>
        <h3 style="color:black">
          Our marvelous MagicGraphs assist you in every step of your learning journey.
        </h3>
      </div>
    </v-layout>
    <br>
    <v-layout
      justify-center
      align-center
      class="mb-3 pa-2"
    >
      <v-flex
        xl4
        sm4
        md4
        lg4
        xl4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggOne.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black">
            Build Concepts
          </h5>
        </v-layout>
      </v-flex>
      <v-flex
        xl4
        sm4
        md4
        lg4
        xl4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggTwo.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black">
            Practice Problems
          </h5>
        </v-layout>
      </v-flex>
      <v-flex
        xl4
        sm4
        md4
        lg4
        xl4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggThree.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black">
            Test Knowledge
          </h5>
        </v-layout>
      </v-flex>
    </v-layout>
    <divide-content :my-style="style" />
    <br>
    <v-layout align-center justify-center>
      <div class="header">
        <b>Let's Get Started</b>
      </div>
    </v-layout>
    <v-layout align-center justify-center>
      <div>
        <h5 style="color:black">
          (Choose a topic to explore)
        </h5>
      </div>
    </v-layout>
    <br>

    <!-- To trigger the pop-up card
    <v-layout
      v-if="popUp"
      justify-center
      align-center
      style="opacity:0.85; position:fixed; top:0;bottom:0;left:0;right:0;z-index:100;"
    >
      <LoginDialog :status="popUp" @close="closeModal" />
    </v-layout>
 -->
    <div v-for="(group, groupIndex) in topics" :key="groupIndex">
      <v-layout justify-start align-center>
        <v-img :src="group.img"
               max-height="40px"
               max-width="40px"
               contain
               class="pa-1"
        />
        <!--
       <v-divider vertical class="pa-1"></v-divider> -->
        <!--    <v-img :src="`/assets/${groupIndex+1}.svg`"
               max-height="45px"
               max-width="45px"
               contain
        /> -->
        <!--  <v-img :src="`/assets/${group.lock ? 'premium' : 'free'}.svg`"
                 max-height="45px"
                 max-width="45px"
                 contain
          /> -->
        <div class="header pa-1">
          <b>{{ group.name }}</b>
        </div>
        <!-- Free icon over element -->
        <v-img :src="`/assets/${group.lock ? 'premium' : 'free'}.svg`"
               max-height="25px"
               max-width="25px"
               class="mb-3"
               contain
        />
        <!-- Premium icon over element
        <v-img :src="`/assets/premium.svg`"
               max-height="20px"
               max-width="20px"
               class="mb-3"
               contain
        />
      -->
      </v-layout>
      <!--<v-img :src="group.img"
               max-height="45px"
               max-width="45px"
               contain

        <v-flex xs2 sm2 md2 lg2 xl2>
          <v-img :src="`/assets/premium.svg`"
                 max-height="20px"
                 max-width="20px"
                 class="mb-3"
                 contain
          />
        </v-flex> -->

      <br>
      <v-layout justify-start raw wrap>
        <v-flex
          v-for="(topic, topicIndex) in group.data"
          :key="topicIndex"
          xs12
          sm4
          md4
          lg4
          xl4
          class="pa-1"
        >
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              class="checkboard-card"
              :color="getColor(topic.isBlack)"
              :class="[`elevation-${hover ? 17 : 2}`,
                       {'white--text': topic.isBlack},
                       `${getCardDisplayClass(topic.icon, hover)}`]"
              @click.native="`${isLocked(topic.icon) ? logged=true : topic.action()}`"
            >
              <v-card-title>
                <v-layout justify-start>
                  <div class="cardheader">
                    <b>{{ groupIndex+1 }} . </b>  <b>{{ topicIndex+1 }}</b>
                  </div>
                </v-layout>
                <div>
                  <transition
                    enter-active-class="animated fadeIn zoomIn"
                    leave-active-class="animated fadeOut zoomOut"
                  >
                    <v-icon
                      v-if="hover==true && loggedIn()==false"
                      :class="[`${isLocked(topic.icon) ? 'lock' : 'open'}`]"
                    >
                      {{ topic.icon }}
                    </v-icon>
                    <v-icon
                      v-else-if="hover==true && loggedIn()==true"
                      :class="[`${isLocked(topic.icon) ? 'lock' : 'open'}`]"
                    >
                      fas fa-lock-open
                    </v-icon>
                  </transition>
                </div>
              </v-card-title>
              <v-layout justify-center align-center class="px-3 cardheader">
                <b>{{ topic.title }} </b>
              </v-layout>
              <!--<v-card-title>
                <div class="shift">
                <span class="spaned" v-for="(names, index) in topic.description">
                 <b style="color:#FF5936;font-weight:900">|</b>&nbsp;{{names}}&nbsp;
                </span>
                </div>
              </v-card-title>-->
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
      <br>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BuyCard from '../BuyCard/BuyCard.vue';
import DivideContent from  './Divider.vue';

export default {
  name: 'Grade7Chem',
  components: {
    BuyCard,
    DivideContent

  },
  data () {
    return {
    logged:false,
    price: null,
    newPrice:null,
    title: null,
    topicss:null,
    discount: null,
    //dialog: true,
    courseStatus: null,
    subscriptionId: null,
    subscriptionEnd: null,
    loginStatus: false,
    style: {layout: "margin: 0%"}
    //buttonName: "Sign In"
  //  popUp:false,
    }
  },
  //async
  created () {
  //    await this.$store.dispatch('navigation/getData');
      this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/changeTitle', 'Grade 7 Chemistry');
  //    this.$store.commit('navigation/changeMenu', 'Grade 6 Math');
      this.$store.commit('navigation/toggleshowhome', false);
      this.$store.commit('navigation/replaceChem', true);
      this.$store.commit('navigation/replaceLeftArrow', true);
      this.$store.commit('navigation/replaceEnroll', true);
  },
  computed: {
     ...mapGetters({
       topics: 'navigation/chemistry7th'
     })
   },
///////////////////////////////////////// CHECKOUT STUFF ///////////////////////////////////////////////
  async mounted () {
    console.log("async mounted working");
    try {
          if(this.$route.query.grade !== undefined)
          {
            console.log("Grade found");
            let chapterUrl = process.env.VUE_APP_API+'course/'+this.$route.query.grade+'/chapters';
            if (localStorage.getItem('userId'))
            {
              console.log("User token found");
              chapterUrl = process.env.VUE_APP_API+'course/'+this.$route.query.grade+'/chapters/'+localStorage.getItem('userId')
              this.loginStatus = true
              this.buttonName = "Subscribe"
            }
            const response = await axios.get(chapterUrl, { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
            if(response.data.success)
            {
              let grades = response.data.data.course;
              this.price = (grades.actual_price).toFixed(2);
              this.discount = 1*grades.discount;
              this.newPrice = ((this.price) * ((100-grades.discount)/100)).toFixed(2);
              console.log("data success", this.price);
              if(typeof grades.subscription_status !== 'undefined')
              {
                console.log("sub", grades.subscription_status);
                this.courseStatus = grades.subscription_status;
                this.subscriptionId = grades.app_subscription_id
                this.subscriptionEnd = grades.ends_at
              }
              console.log("data success", this.courseStatus, this.subscriptionEnd);
            }
              this.$store.commit('navigation/changePrice', this.price);
              this.$store.commit('navigation/changeDiscount', this.discount);
              this.$store.commit('navigation/changenewPrice', this.newPrice);
              this.$store.commit('navigation/courseStatusInd', this.courseStatus);
              this.$store.commit('navigation/appSubscriptionId', this.subscriptionId);
              this.$store.commit('navigation/appSubscriptionEnd', this.subscriptionEnd);

          }
        }
        catch (error)
        {
          console.error("error1::", error);
          this.$notify({
                group: 'notify',
                type: 'error',
                title: 'Error!!',
                text: 'Something went wrong!!'
              });
        }
  },
/////////////////////////////////////// THESE ARE LOCAL METHODS SCOPED TO THIS VUE COMPONENET ONLY. IF THESE FUNCTIONS HAVE TO BE USED MORE OFTEN, PLACE THEM WITHIN VUE MIXIN INSIDE MAIN.JS FILE
  methods:
  {
    showCard ()
    {
      if(!localStorage.getItem('token'))
      {
      this.logged = true;
      }
      else
      {
      }
    },
    closeBuy()
    {
      this.logged=false;
      console.log(this.logged);
    },

    getColor(isBlack)
    {
      return isBlack ? '#2E2E2E' : '#e0e0e0';
    },
    isLocked(iconString)
    {  if(!localStorage.getItem('token'))
      {
        return iconString == 'fas fa-lock';
      } else {
        return false;
      }
    },
    getCardDisplayClass(iconString, hoverState)
    {
        if (hoverState)
        {
            if (this.isLocked(iconString))
            {
                return 'locked-content';
            } else
            {
                return 'unlocked-content';
            }
        }
        return '';
    }
  },
  metaInfo() {
  return{ title: 'Explore 7th Grade Chemistry',
          //titleTemplate: '%s - Learn interactively',
          meta: [
                  {vmid:'keywords', name: "keywords", content: "7th grade chemistry, Interactive chemistry, Quizzes"},
                  {vmid:'description',name: 'description', content: 'Visually interactive module on 6th grade mathematics'}
                ],
        }
   }
}
</script>
<style lang="scss">
@import '@/styles/screen-sizes.scss';
</style>
